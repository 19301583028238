import axios from "axios";
import { createContext, useContext, useMemo, useState } from "react";
import { url } from "../config";
import { toast } from "react-toastify";
import { useAuth } from "./AuthProvider";

const NotificationContext = createContext();

const NotificationProvider = ({ children }) => {
    const [loading, setLoading] = useState(true);

    const { token, logged } = useAuth();

    async function sendToGroup(title, description, image, groups) {
        console.log(title, description, image, groups);
        setLoading(true);

        if (!logged) {
            return;
        }

        // Create a new FormData instance
        var formData = new FormData();
        formData.append('image', image);
        formData.append('title', title);
        formData.append('description', description);

        // Convert groups array to JSON string
        formData.append('groups', JSON.stringify(groups));

        try {
            const res = await axios.post(
                `${url}/admin/notification/groups`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );
            console.log(res);
            toast.success("Notifications sent successfully!");
            return res.data;
        } catch (error) {
            console.error("Error sending notification:", error);
            if (error.response?.status === 400) {
                toast.error(error.response.data.errors);
            } else {
                toast.error("Something went wrong!");
            }
            throw new Error(error);
        }
    }

    async function sendToAllUsers(title, description, image) {
        {
            console.log(title, description, image)
            setLoading(true);
            if (!logged) {
                return;
            }
            // using multipart formdata to upload images
            var formData = new FormData();
            formData.append('image', image);
            // adding other fields 
            formData.append('title', title);
            formData.append('description', description);
            try {
                const res = await axios.post(
                    `${url}/admin/notification/all-users`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${token}`,
                        }
                    }
                );
                console.log(formData)
                console.log(res)
                toast.success("Notifications sent successfully !");
                return res.data;
            } catch (error) {
                if (error.response?.status === 400) {
                    toast.error(error.response.data.errors);
                } else {
                    toast.error("Something went wrong!");
                }
                throw Object.assign(
                    new Error(error),
                    { code: 500 }
                );
            }
        }
    }

    async function sendToSpecificUsers(title, description, image, users) {
        console.log("Payload to be sent:", { title, description, image, users });
        setLoading(true);

        if (!logged) {
            toast.error("User not logged in!");
            return;
        }

        // Using multipart form-data for image upload
        const formData = new FormData();
        formData.append('image', image);
        formData.append('title', title);
        formData.append('description', description);

        // Convert users array to JSON string before appending
        formData.append('users', JSON.stringify(users));

        try {
            const response = await axios.post(
                `${url}/admin/notification/users`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );

            console.log("Response received:", response);
            toast.success("Notifications sent successfully!");
            return response.data;
        } catch (error) {
            console.error("Error in sendToSpecificUsers API:", error);

            if (error.response) {
                const { status, data } = error.response;

                if (status === 400 && data.errors) {
                    toast.error(data.errors);
                } else {
                    toast.error(data.message || "Something went wrong!");
                }
            } else {
                toast.error("Network error or server is unreachable!");
            }

            throw new Error(error.message || "Error occurred while sending notifications");
        } finally {
            setLoading(false);
        }
    }

    // Memoized value of the authentication context
    const contextValue = useMemo(
        () => ({
            logged,
            loading,
            sendToGroup,
            sendToAllUsers,
            sendToSpecificUsers
        }),
        [
            logged,
            loading,
            sendToGroup,
            sendToAllUsers,
            sendToSpecificUsers
        ]
    );

    // Provide the authentication context to the children components
    return (
        <NotificationContext.Provider value={contextValue}>{children}</NotificationContext.Provider>
    );
};

export const useNotification = () => {
    return useContext(NotificationContext);
};

export default NotificationProvider;



