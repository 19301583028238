import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    Button,
    VStack,
    ModalHeader,
    ModalCloseButton,
    HStack,
    FormControl,
    Textarea,
    Text,
    Select,
} from '@chakra-ui/react';
import { useFormik } from "formik";

export default function TicketReply({ isOpen, onClose, ticketDetails }) {
    const formik = useFormik({
        initialValues: {
            reply_text: "",
            status: ticketDetails.status
        },
        onSubmit: async (values) => {
            // await editFaq(faq._id, values.faq_question, values.faq_answer);
            console.log(values.reply_text, values.status);
            onClose();
        }
    });

    const statusColors = {
        'Closed': 'green.500',
        'Open': 'blue.500',
        'Inprocess': 'yellow.500'
    };

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} isCentered onOpen size={{ base: 'sm', md: 'xl' }}>
                <ModalOverlay />
                <ModalContent mx={{ base: 4, md: 0 }}>
                    <ModalHeader mx={0} w={'auto'}>
                        <VStack alignItems={'start'} spacing={1}>
                            <Text fontSize={'sm'} fontWeight={'600'}>Reply</Text>
                            <Text fontSize={'xs'} fontWeight={'400'}>Ticket ID: #1234</Text>
                        </VStack>
                    </ModalHeader>
                    <Text></Text>
                    <ModalCloseButton />

                    <VStack mx={5} w={'auto'} alignItems={'start'}>
                        <HStack spacing={4} mb={1} w="100%">
                            <Text fontSize={'xs'} fontWeight={'500'}>
                                Status:
                            </Text>
                            <Select
                                id="status"
                                name="status"
                                value={formik.values.status}
                                onChange={formik.handleChange}
                                size="sm"
                                fontWeight={'500'}
                                width="auto"
                                color={statusColors[formik.values.status] || 'gray.500'}
                            >
                                <option value="Open">Open</option>
                                <option value="Inprocess">In Process</option>
                                <option value="Closed">Closed</option>
                            </Select>
                        </HStack>

                        <Text fontSize={'md'} color='primary.800' fontWeight={'600'}>{ticketDetails.title}</Text>
                        <Text fontSize={'sm'} fontWeight={'400'}>{ticketDetails.description}</Text>
                    </VStack>

                    <form onSubmit={formik.handleSubmit}>
                        <VStack w={'auto'} alignItems={'start'} mx={5} mt={6}>
                            <FormControl>
                                <Textarea
                                    id="reply_text"
                                    name="reply_text"
                                    type="text"
                                    variant="filled"
                                    required={true}
                                    fontSize="sm"
                                    py={4}
                                    bg="none"
                                    borderColor="gray.100"
                                    _focus={{
                                        borderColor: "primary.700",
                                    }}
                                    borderRadius="10"
                                    value={formik.values.reply_text}
                                    onChange={formik.handleChange}
                                />
                            </FormControl>

                            <ModalFooter w={'100%'} px={{ lg: 44 }}>
                                <Button
                                    my={4}
                                    type="submit"
                                    bg="primary.700"
                                    py='6'
                                    width="full"
                                    borderRadius={'50'}
                                    fontSize={'sm'}
                                    color={'white'}
                                >
                                    Send Reply
                                </Button>
                            </ModalFooter>

                        </VStack>
                    </form>
                </ModalContent>
            </Modal>
        </>
    )
}